import BuyArea from '../../components/buy-area';

import { Preview } from './components/preview';
import SuggestionsBrowser from './components/suggestions-browser';
import { WordLimitFooter } from './components/word-limit-footer';
import * as s from './editor-workspace.styles';

const EditorWorkspace: React.FC = () => {
  return (
    <>
      <s.asideLeft />
      <s.editor>
        <Preview />
        <WordLimitFooter />
      </s.editor>

      <s.asideRight>
        <s.asideRightInner>
          <SuggestionsBrowser />
          <BuyArea />
        </s.asideRightInner>
      </s.asideRight>
    </>
  );
};

export default EditorWorkspace;
